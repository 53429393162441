import { FC, MouseEventHandler, useEffect } from "react";
import { ReactComponent as CloseSVG } from "../../../assets/icons/notifications/clear.svg";
import { ReactComponent as WarningMessageSVG } from "../../../assets/icons/notifications/warningNotification.svg";
import { ReactComponent as ErrorMessageSVG } from "../../../assets/icons/notifications/errorNotification.svg";
import { ReactComponent as DoneMessageSVG } from "../../../assets/icons/notifications/doneNotification.svg";
import { Status, Notification } from "../../../redux/notification/types";
import {
  StyledFlashMessageDiv,
  StyledFlashMessageContentDiv,
  StyledFlashMessageBodyDiv,
  StyledFlashMessageIcon,
  StyledFlashMessage,
  StyledFlashMessageTitle,
  StyledFlashMessageCloseButton,
} from "../../../styles/NotificationItem";

interface IProps {
  notification: Notification;
  closeNotification: MouseEventHandler<HTMLButtonElement>;
}

const NotificationItem: FC<IProps> = ({ notification, closeNotification }) => {
  useEffect(() => {
    let timer: number;
    // eslint-disable-next-line prefer-const
    timer = window.setTimeout(closeNotification, 3000);
    return () => clearTimeout(timer);
  }, []);

  const renderMessageSVG = (status: Status) => {
    switch (status) {
      case Status.WARNING:
        return <WarningMessageSVG />;
      case Status.ERROR:
        return <ErrorMessageSVG />;
      case Status.DONE:
        return <DoneMessageSVG />;
      default:
        return null;
    }
  };

  return (
    <StyledFlashMessageDiv>
      <StyledFlashMessageContentDiv>
        <StyledFlashMessageBodyDiv>
          <StyledFlashMessageIcon>
            {renderMessageSVG(notification.status)}
          </StyledFlashMessageIcon>
          <div>
            <StyledFlashMessageTitle data-testid="notice-title">
              {notification.title}
            </StyledFlashMessageTitle>
            <StyledFlashMessage data-testid="notice-message">
              {notification.message}
            </StyledFlashMessage>
          </div>
        </StyledFlashMessageBodyDiv>
        <StyledFlashMessageCloseButton
          data-testid="close-notice-btn"
          type="button"
          onClick={closeNotification}
        >
          <CloseSVG />
        </StyledFlashMessageCloseButton>
      </StyledFlashMessageContentDiv>
    </StyledFlashMessageDiv>
  );
};

export default NotificationItem;
