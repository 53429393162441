import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { unstable_usePrompt, useNavigate } from "react-router-dom";
import {
  StyledCreateBannerPageDiv,
  StyledSaveBannerButton,
  StyledBannerButtonContainer,
  StyledCreateBannerContainerDiv,
  StyledTitleContainer,
  StyledMainBannerDiv,
  StyledTextFields,
  StyledAdditionalPageBannerDiv,
  StyledDeleteImageButton,
} from "../../styles/CreateBannerPage";
import { IBannerInfo, IBannerInfoError, ITextFieldInfo } from "./types";
import {
  getLinkErrorMessage,
  handleCheckCroppedContentImage,
  handleCheckCroppedImage,
  handleCheckDescriptionField,
  handleCheckLinkToContentPageField,
  handleCheckLinkToFileField,
  handleCheckSubtitleField,
  handleCheckTitleField,
} from "../../constants/banner";
import { store } from "../../redux/store";
import { createBanner, getBannerList } from "../../redux/banners/actions";
import StyledTextField from "../../styles/TextField";
import { StyledCropButton } from "../../styles/CropImageModal";
import BannerImagePreview from "../../components/bannerPreview/bannerImagePreview/BannerImagePreview";
import BannerContentPreview from "../../components/bannerPreview/bannerContentPreview/BannerContentPreview";
import CropBannerImageModal from "../../components/cropImageModal/CropBannerImageModal";
import CropContentBannerImageModal from "../../components/cropImageModal/CropContentBannerImageModal";
import convertDataURLtoFile from "../../utils/banner/convertDataUrlToFile";
import ModalWarningDeleteCroppedImage from "../../components/modalDeleteCroppedImage/ModalWarningDelete";

const CreateBanner: FC = (): React.ReactElement => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const [isChanged, setIsChanged] = useState(false);
  const [showCropMainImageModal, setShowCropMainImageModal] = useState(false);
  const [
    showModalWarningBannerImageDelete,
    setShowModalWarningBannerImageDelete,
  ] = useState(false);
  const [
    showModalWarningContentBannerImageDelete,
    setShowModalWarningContentBannerImageDelete,
  ] = useState(false);
  const navigate = useNavigate();
  const [showCropContainerImageModal, setShowCropContainerImageModal] =
    useState(false);

  const initialBannerInfo: IBannerInfo = {
    linkToBannerImage: "",
    title: "",
    linkToContentPageBannerImage: "",
    subtitle: "",
    description: "",
    croppedBannerImage: "",
    croppedContentBannerImage: "",
  };
  const [bannerInfo, setBannerInfo] = useState<IBannerInfo>(initialBannerInfo);

  const [bannerInfoError, setBannerInfoError] = useState<IBannerInfoError>({
    linkToBannerImageError: false,
    titleError: false,
    linkToContentPageBannerImageError: false,
    subtitleError: false,
    descriptionError: false,
    croppedBannerImage: false,
    croppedContentBannerImage: false,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const linkToFileError = getLinkErrorMessage(
    bannerInfoError.linkToBannerImageError,
    bannerInfoError.croppedBannerImage,
  );

  const linkToContentFileError = getLinkErrorMessage(
    bannerInfoError.linkToContentPageBannerImageError,
    bannerInfoError.croppedContentBannerImage,
  );

  const onChangeBannerInfo = (info: ITextFieldInfo[] | ITextFieldInfo) => {
    const newData = Array.isArray(info)
      ? info.reduce(
          (acc: Record<string, string | undefined>, item: ITextFieldInfo) => {
            acc[item.key] = item.image || item.text;
            return acc;
          },
          {},
        )
      : { [info.key]: info.text || info.image };

    setBannerInfo((prevState) => ({
      ...prevState,
      ...newData,
    }));
    setIsChanged(true);
  };

  const handleCroppedBannerImageDelete = () => {
    setShowModalWarningBannerImageDelete(true);
  };

  const handleCroppedContentBannerImageDelete = () => {
    setShowModalWarningContentBannerImageDelete(true);
  };

  const deleteCroppedImage = () => {
    onChangeBannerInfo([
      {
        key: "croppedBannerImage",
        text: "",
        image: "",
      },
      { key: "linkToBannerImage", text: "" },
    ]);
    setShowModalWarningBannerImageDelete(false);
  };

  const deleteCroppedContentImage = () => {
    onChangeBannerInfo([
      {
        key: "croppedContentBannerImage",
        text: "",
        image: "",
      },
      { key: "linkToContentPageBannerImage", text: "" },
    ]);
    setShowModalWarningContentBannerImageDelete(false);
  };

  const submitBanner = () => {
    setIsChanged(false);
    setIsFirstLoad(false);

    const croppedBannerImageFile = convertDataURLtoFile(
      bannerInfo.croppedBannerImage as string,
      "banner.jpeg",
    );
    const croppedContentBannerImageFile = convertDataURLtoFile(
      bannerInfo.croppedContentBannerImage as string,
      "banner.jpeg",
    );

    if (
      bannerInfo.croppedBannerImage?.length &&
      bannerInfo.croppedContentBannerImage?.length &&
      bannerInfo.title.length &&
      bannerInfo.subtitle.length &&
      bannerInfo.description.length
    ) {
      dispatch(
        createBanner({
          banner_image: croppedBannerImageFile,
          banner_title: bannerInfo.title,
          content_image: croppedContentBannerImageFile,
          content_title: bannerInfo.subtitle,
          content_text: bannerInfo.description,
          action: getBannerList(1),
          navigate: () => navigate("/"),
        }),
      );
    }
  };

  unstable_usePrompt({
    message: "Are you sure you want to cancel changes?",
    when: isChanged,
  });

  useEffect(() => {
    if (!isFirstLoad) {
      handleCheckLinkToFileField(bannerInfo, setBannerInfoError);
      handleCheckTitleField(bannerInfo, setBannerInfoError);
      handleCheckLinkToContentPageField(bannerInfo, setBannerInfoError);
      handleCheckSubtitleField(bannerInfo, setBannerInfoError);
      handleCheckDescriptionField(bannerInfo, setBannerInfoError);
      handleCheckCroppedImage(bannerInfo, setBannerInfoError);
      handleCheckCroppedContentImage(bannerInfo, setBannerInfoError);
    }
  }, [isFirstLoad, submitBanner]);

  return (
    <StyledCreateBannerPageDiv>
      <StyledCreateBannerContainerDiv>
        <StyledTitleContainer>
          <span>Create banner</span>
          <span>Preview</span>
        </StyledTitleContainer>
        <StyledMainBannerDiv>
          <StyledTextFields>
            {bannerInfo.croppedBannerImage?.length ? (
              <StyledDeleteImageButton
                type="button"
                onClick={handleCroppedBannerImageDelete}
              >
                Delete image
              </StyledDeleteImageButton>
            ) : (
              ""
            )}
            <div>
              <StyledTextField
                required
                label="Link to file"
                error={!!linkToFileError}
                helperText={linkToFileError}
                value={bannerInfo.linkToBannerImage}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChangeBannerInfo({
                    key: "linkToBannerImage",
                    text: e.target.value,
                  });
                }}
              />
              {bannerInfo.linkToBannerImage && (
                <StyledCropButton
                  onClick={() => setShowCropMainImageModal(true)}
                  variant="contained"
                  color="primary"
                >
                  Add
                </StyledCropButton>
              )}
            </div>
            <StyledTextField
              required
              label="Title"
              error={bannerInfoError.titleError}
              helperText={bannerInfoError.titleError && "Please enter title"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeBannerInfo({
                  key: "title",
                  text: e.target.value,
                });
              }}
              value={bannerInfo.title}
              inputProps={{ maxLength: 256, minLength: 1 }}
            />
          </StyledTextFields>
          <BannerImagePreview imageUrl={bannerInfo.croppedBannerImage} />
        </StyledMainBannerDiv>
        <StyledAdditionalPageBannerDiv>
          <StyledTextFields>
            {bannerInfo.croppedContentBannerImage?.length ? (
              <StyledDeleteImageButton
                type="button"
                onClick={handleCroppedContentBannerImageDelete}
              >
                Delete image
              </StyledDeleteImageButton>
            ) : (
              ""
            )}
            <div>
              <StyledTextField
                required
                label="Link to file"
                inputProps={{ minLength: 1 }}
                error={!!linkToContentFileError}
                helperText={linkToContentFileError}
                value={bannerInfo.linkToContentPageBannerImage}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChangeBannerInfo({
                    key: "linkToContentPageBannerImage",
                    text: e.target.value,
                  });
                }}
              />
              {bannerInfo.linkToContentPageBannerImage && (
                <StyledCropButton
                  onClick={() => setShowCropContainerImageModal(true)}
                  variant="contained"
                  color="primary"
                >
                  Add
                </StyledCropButton>
              )}
            </div>
            <StyledTextField
              required
              label="Subtitle"
              value={bannerInfo.subtitle}
              error={bannerInfoError.subtitleError}
              helperText={
                bannerInfoError.subtitleError && "Please enter subtitle"
              }
              inputProps={{ maxLength: 256, minLength: 1 }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeBannerInfo({
                  key: "subtitle",
                  text: e.target.value,
                });
              }}
            />
            <StyledTextField
              required
              label="Description"
              value={bannerInfo.description}
              multiline
              rows={8}
              error={bannerInfoError.descriptionError}
              helperText={
                bannerInfoError.descriptionError && "Please enter description"
              }
              inputProps={{ maxLength: 2048, minLength: 1 }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeBannerInfo({
                  key: "description",
                  text: e.target.value,
                });
              }}
            />
          </StyledTextFields>
          <BannerContentPreview
            imageUrl={bannerInfo.croppedContentBannerImage}
            subtitle={bannerInfo.subtitle}
            description={bannerInfo.description}
          />
        </StyledAdditionalPageBannerDiv>
        {showCropMainImageModal && (
          <CropBannerImageModal
            linkToImage={bannerInfo.linkToBannerImage}
            onCancel={() => setShowCropMainImageModal(false)}
            onConfirm={(croppedImage: string) => {
              setShowCropMainImageModal(false);
              onChangeBannerInfo({
                key: "croppedBannerImage",
                image: croppedImage,
              });
            }}
          />
        )}
        {showCropContainerImageModal && (
          <CropContentBannerImageModal
            linkToImage={bannerInfo.linkToContentPageBannerImage}
            onCancel={() => setShowCropContainerImageModal(false)}
            onConfirm={(croppedImage: string) => {
              setShowCropContainerImageModal(false);
              onChangeBannerInfo({
                key: "croppedContentBannerImage",
                image: croppedImage,
              });
            }}
          />
        )}
        {showModalWarningBannerImageDelete && (
          <ModalWarningDeleteCroppedImage
            onCancel={() => setShowModalWarningBannerImageDelete(false)}
            onConfirm={deleteCroppedImage}
          />
        )}
        {showModalWarningContentBannerImageDelete && (
          <ModalWarningDeleteCroppedImage
            onCancel={() => setShowModalWarningContentBannerImageDelete(false)}
            onConfirm={deleteCroppedContentImage}
          />
        )}
        <StyledBannerButtonContainer>
          <StyledSaveBannerButton variant="contained" onClick={submitBanner}>
            Save banner
          </StyledSaveBannerButton>
        </StyledBannerButtonContainer>
      </StyledCreateBannerContainerDiv>
    </StyledCreateBannerPageDiv>
  );
};

export default CreateBanner;
