import React, { FC } from "react";
import {
  StyledModalWarningDivContainer,
  StyledModalWarningHeader,
  StyledModalWarningButtonContainer,
  StyledModalWarningCancelButton,
  StyledModalWarningDiv,
} from "../../styles/ModalWarning";

interface IModalWarningProps {
  onSave: () => void;
  onSaveAndPublish: () => void;
}

const ModalSaveOptions: FC<IModalWarningProps> = ({
  onSave,
  onSaveAndPublish,
}): React.ReactElement => {
  return (
    <StyledModalWarningDiv>
      <StyledModalWarningDivContainer>
        <StyledModalWarningHeader>
          <p>Do you want to save and publish your changes?</p>
        </StyledModalWarningHeader>
        <StyledModalWarningButtonContainer>
          <StyledModalWarningCancelButton
            variant="outlined"
            onClick={onSaveAndPublish}
          >
            Save and publish
          </StyledModalWarningCancelButton>
          <StyledModalWarningCancelButton variant="outlined" onClick={onSave}>
            Save without publishing
          </StyledModalWarningCancelButton>
        </StyledModalWarningButtonContainer>
      </StyledModalWarningDivContainer>
    </StyledModalWarningDiv>
  );
};

export default ModalSaveOptions;
