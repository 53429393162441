import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as Logo } from "../../assets/icons/sidebar/logo.svg";
import SidebarOption from "./sidebarOption/SidebarOption";
import { IOption } from "./types";
import SIDEBAR_OPTIONS from "./options";
import {
  StyledSidebarDiv,
  StyledSidebarLink,
  StyledSidebarNav,
} from "../../styles/Sidebar";
import { ReactComponent as ExitDefaultIcon } from "../../assets/icons/sidebar/exit/exit_default.svg";
import { ReactComponent as ExitSelectedIcon } from "../../assets/icons/sidebar/exit/exit_selected.svg";
import { store } from "../../redux/store";
import { logout } from "../../redux/auth";
import { logoutRequest } from "../../redux/auth/actions";

const Sidebar: FC = (): React.ReactElement => {
  const dispatch = useDispatch<typeof store.dispatch>();

  const exitOption = {
    id: "exit",
    redirectRoute: "/login",
    activeOnPaths: ["/", "/history", "/banner/create"],
    icon: {
      iconDefault: ExitDefaultIcon,
      iconSelected: ExitSelectedIcon,
    },
    optionText: "Exit",
  };

  const handleExitClick = () => {
    dispatch(logoutRequest());
    dispatch(logout());
  };

  return (
    <StyledSidebarNav>
      <StyledSidebarDiv>
        <StyledSidebarLink to="/">
          <Logo />
        </StyledSidebarLink>
        {SIDEBAR_OPTIONS.map((option: IOption) => (
          <SidebarOption key={option.id} option={option} />
        ))}
        <button
          type="button"
          style={{ backgroundColor: "#FFFFFF", border: "none" }}
          onClick={handleExitClick}
        >
          <SidebarOption option={exitOption} />
        </button>
      </StyledSidebarDiv>
    </StyledSidebarNav>
  );
};

export default Sidebar;
