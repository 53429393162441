import { Dispatch, SetStateAction } from "react";
import { IBannerInfo, IBannerInfoError } from "../pages/createBanner/types";

const linkPattern = new RegExp(
  "^([a-zA-Z]+:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
  "i",
);

const handleCheckLinkToFileField = (
  bannerInfo: IBannerInfo,
  setBannerInfoError: Dispatch<SetStateAction<IBannerInfoError>>,
) => {
  linkPattern.test(bannerInfo.linkToBannerImage)
    ? setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        linkToBannerImageError: false,
      }))
    : setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        linkToBannerImageError: true,
      }));
};

const handleCheckTitleField = (
  bannerInfo: IBannerInfo,
  setBannerInfoError: Dispatch<SetStateAction<IBannerInfoError>>,
) => {
  bannerInfo?.title?.length
    ? setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        titleError: false,
      }))
    : setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        titleError: true,
      }));
};

const handleCheckLinkToContentPageField = (
  bannerInfo: IBannerInfo,
  setBannerInfoError: Dispatch<SetStateAction<IBannerInfoError>>,
) => {
  linkPattern.test(bannerInfo.linkToContentPageBannerImage)
    ? setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        linkToContentPageBannerImageError: false,
      }))
    : setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        linkToContentPageBannerImageError: true,
      }));
};

const handleCheckSubtitleField = (
  bannerInfo: IBannerInfo,
  setBannerInfoError: Dispatch<SetStateAction<IBannerInfoError>>,
) => {
  bannerInfo?.subtitle?.length
    ? setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        subtitleError: false,
      }))
    : setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        subtitleError: true,
      }));
};

const handleCheckDescriptionField = (
  bannerInfo: IBannerInfo,
  setBannerInfoError: Dispatch<SetStateAction<IBannerInfoError>>,
) => {
  bannerInfo?.description?.length
    ? setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        descriptionError: false,
      }))
    : setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        descriptionError: true,
      }));
};

const handleCheckCroppedImage = (
  bannerInfo: IBannerInfo,
  setBannerInfoError: Dispatch<SetStateAction<IBannerInfoError>>,
) => {
  bannerInfo.croppedBannerImage?.length
    ? setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        croppedBannerImage: false,
      }))
    : setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        croppedBannerImage: true,
      }));
};

const handleCheckCroppedContentImage = (
  bannerInfo: IBannerInfo,
  setBannerInfoError: Dispatch<SetStateAction<IBannerInfoError>>,
) => {
  bannerInfo.croppedContentBannerImage?.length
    ? setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        croppedContentBannerImage: false,
      }))
    : setBannerInfoError((prevState: IBannerInfoError) => ({
        ...prevState,
        croppedContentBannerImage: true,
      }));
};

const getLinkErrorMessage = (linkError: boolean, fileError: boolean) => {
  if (linkError) return "Please enter correct link";
  if (fileError) return "Press 'Add' to upload the image";
  return "";
};

export {
  handleCheckLinkToFileField,
  handleCheckTitleField,
  handleCheckLinkToContentPageField,
  handleCheckSubtitleField,
  handleCheckDescriptionField,
  linkPattern,
  handleCheckCroppedImage,
  handleCheckCroppedContentImage,
  getLinkErrorMessage,
};
