import styled from "styled-components";

const StyledImage = styled.img`
  width: 361px;
  height: 186px;
  border-radius: 15px;
  position: absolute;
  left: 18px;
  top: 128px;
`;

const StyledImageContainer = styled.img`
  position: relative;
  border-radius: 15px;
  width: 393px;
  height: 335px;
  border: 2px solid white;
`;

const StyledContentImage = styled.img`
  width: 393px;
  height: 302px;
`;

const StyledBannerContentPreviewDiv = styled.div`
  background-color: white;
  max-width: 393px;
  font-size: 18px;
  font-family: "Roobert PRO", sans-serif;
  border-radius: 15px;
  border: 2px solid white;
`;

const StyledSubtitle = styled.h3`
  padding: 0 24px;
`;

const StyledDescription = styled.p`
  color: #3d495c;
  padding: 0 24px;
  font-size: 14px;
  line-height: 150%;
  align-self: flex-start;
  overflow-wrap: anywhere;
`;

const StyledBorderImage = styled.img`
  width: 393px;
`;

export {
  StyledImage,
  StyledContentImage,
  StyledBannerContentPreviewDiv,
  StyledImageContainer,
  StyledBorderImage,
  StyledSubtitle,
  StyledDescription,
};
