import { createAsyncThunk } from "@reduxjs/toolkit";
import BASE_URL from "../../constants/API";
import makeRequest from "../../api/makeRequest";
import {
  IBannerListRespError,
  ICreateBannerPayload,
  IEditBannerPayload,
} from "./types";
import { setNotification } from "../notification";
import { Status } from "../notification/types";
import { IAuthRespError, IContentError } from "../auth/types";
import getBannerErrorMessage from "../../utils/api/getErrorMeaage";

const getBannerList = createAsyncThunk(
  "banners/getBannerList",
  async (pageNumber: number, thunkApi) => {
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners?page=${pageNumber}`,
      {
        method: "GET",
      },
    );
    if (!response.ok) {
      const errorData: IBannerListRespError = await response.json();
      return thunkApi.rejectWithValue(errorData);
    }
    return response.json();
  },
);

const getBannerHistory = createAsyncThunk(
  "banners/getBannerHistory",
  async (payload: { id: number; pageNumber?: number }, thunkApi) => {
    const { id, pageNumber } = payload;
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners-history/${id}?page=${pageNumber}`,
      {
        method: "GET",
      },
    );
    if (!response.ok) {
      const errorData: IBannerListRespError = await response.json();
      return thunkApi.rejectWithValue(errorData);
    }
    return response.json();
  },
);

const getBannersChangeLog = createAsyncThunk(
  "banners/getBannersChangeLog",
  async (pageNumber: number, thunkApi) => {
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners-history?page=${pageNumber}`,
      {
        method: "GET",
      },
    );
    if (!response.ok) {
      const errorData: IBannerListRespError = await response.json();
      return thunkApi.rejectWithValue(errorData);
    }
    return response.json();
  },
);

const getSortedBannerList = createAsyncThunk(
  "banners/getSortedBannerList",
  async (
    payload: { pageNumber: number; sortType: "asc" | "desc" },
    thunkApi,
  ) => {
    const { pageNumber, sortType } = payload;
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners?page=${pageNumber}&sort=${sortType}`,
      {
        method: "GET",
      },
    );
    if (!response.ok) {
      const errorData: IBannerListRespError = await response.json();
      return thunkApi.rejectWithValue(errorData);
    }
    return response.json();
  },
);

const getBannerDetails = createAsyncThunk(
  "banners/getBannerDetails",
  async (id: number, thunkApi) => {
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners/${id}`,
      {
        method: "GET",
      },
    );
    if (!response.ok) {
      const errorData: IBannerListRespError = await response.json();
      return thunkApi.rejectWithValue(errorData);
    }
    return response.json();
  },
);

const changeBannerStatus = createAsyncThunk(
  "banners/changeBannerStatus",
  async (payload: { id: number; action?: any }, thunkApi) => {
    const { id, action } = payload;
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners/change-status/${id}`,
      {
        method: "POST",
      },
    );
    if (!response.ok) {
      const errorData: IAuthRespError = await response.json();
      thunkApi.dispatch(
        setNotification({
          title: "Change status error",
          message: errorData.message,
          status: Status.ERROR,
        }),
      );
    } else if (response.status === 200 && action) {
      thunkApi.dispatch(action);
    }
  },
);

const changePriorityOfBanner = createAsyncThunk(
  "banners/changePriorityOfBanner",
  async (
    payload: { id: number; orderNumber: string; action?: any },
    thunkApi,
  ) => {
    const { id, orderNumber, action } = payload;
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners/change-priority/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ priority: orderNumber }),
      },
    );
    if (!response.ok) {
      const errorData: IAuthRespError = await response.json();
      thunkApi.dispatch(
        setNotification({
          title: "Change order error",
          message: errorData.message,
          status: Status.ERROR,
        }),
      );
    }
    if (response.status === 200) {
      thunkApi.dispatch(
        setNotification({
          title: "Priority changed",
          message: "Banner priority successfully changed",
          status: Status.DONE,
        }),
      );
      action && thunkApi.dispatch(action);
    }
  },
);

const deleteBanner = createAsyncThunk(
  "banners/deleteBanner",
  async (
    payload: { id: number; action?: any; navigate?: Function },
    thunkApi,
  ) => {
    const { id, action, navigate } = payload;
    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners/delete/${id}`,
      {
        method: "DELETE",
      },
    );
    if (response.status === 200) {
      action && thunkApi.dispatch(action);
      navigate && navigate("/");
    }
  },
);

const createBanner = createAsyncThunk(
  "banners/createBanner",
  async (payload: ICreateBannerPayload, thunkApi) => {
    const {
      banner_title,
      banner_image,
      content_title,
      content_image,
      content_text,
      action,
      navigate,
    } = payload;
    const formData = new FormData();
    formData.append("banner_title", banner_title as string | Blob);
    formData.append(
      "banner_image",
      banner_image as string | Blob,
      "banner.png",
    );
    formData.append("content_title", content_title);
    formData.append(
      "content_image",
      content_image as string | Blob,
      "content-banner.png",
    );
    formData.append("content_text", content_text);

    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners/create`,
      {
        method: "POST",
        body: formData,
      },
    );

    if (response.status === 200 || response.status === 201) {
      thunkApi.dispatch(
        setNotification({
          title: "Banner Created",
          message: "Banner successfully created",
          status: Status.DONE,
        }),
      );
      action && thunkApi.dispatch(action);
      navigate && navigate("/");
    } else if (response.status === 422) {
      const errorData: IContentError = await response.json();

      thunkApi.dispatch(
        setNotification({
          title: "Banner Create Error",
          message: getBannerErrorMessage(errorData),
          status: Status.ERROR,
        }),
      );
    } else if (response.status === 413) {
      thunkApi.dispatch(
        setNotification({
          title: "Banner Create Error",
          message: "The image is too big, please pick another one",
          status: Status.ERROR,
        }),
      );
    } else {
      thunkApi.dispatch(
        setNotification({
          title: "Banner Create Error",
          message: "Something went wrong",
          status: Status.ERROR,
        }),
      );
    }
  },
);

const editBanner = createAsyncThunk(
  "banners/editBanner",
  async (payload: IEditBannerPayload, thunkApi) => {
    const {
      id,
      banner_title,
      banner_image,
      content_title,
      content_image,
      content_text,
      action,
      successCallback,
    } = payload;
    const formData = new FormData();
    formData.append("banner_title", banner_title);
    formData.append("banner_image", banner_image, "banner.jpeg");
    formData.append("content_title", content_title);
    formData.append("content_image", content_image, "content-banner.jpeg");
    formData.append("content_text", content_text);

    const response = await makeRequest(
      thunkApi.dispatch,
      `${BASE_URL}/banners/edit/${id}`,
      {
        method: "POST",
        body: formData,
      },
    );

    if (response.status === 200 || response.status === 201) {
      thunkApi.dispatch(
        setNotification({
          title: "Banner Edited",
          message: "Banner successfully edited",
          status: Status.DONE,
        }),
      );
      action && thunkApi.dispatch(action);
      successCallback();
    } else if (response.status === 422) {
      const errorData: IContentError = await response.json();

      thunkApi.dispatch(
        setNotification({
          title: "Banner Edit Error",
          message: getBannerErrorMessage(errorData),
          status: Status.ERROR,
        }),
      );
    } else if (response.status === 413) {
      thunkApi.dispatch(
        setNotification({
          title: "Banner Edit Error",
          message: "The image is too big, please pick another one",
          status: Status.ERROR,
        }),
      );
    } else {
      const errorData: IAuthRespError = await response.json();

      thunkApi.dispatch(
        setNotification({
          title: "Banner Edit Error",
          message: errorData.message,
          status: Status.ERROR,
        }),
      );
    }
  },
);

export {
  getBannerList,
  createBanner,
  getBannerDetails,
  deleteBanner,
  changeBannerStatus,
  editBanner,
  getSortedBannerList,
  changePriorityOfBanner,
  getBannersChangeLog,
  getBannerHistory,
};
