import styled from "styled-components";

const StyledCardListDiv = styled.div<{ currentPage: string }>`
  max-width: 90%;
  display: flex;
  gap: 35px 100px;
  flex-wrap: wrap;
  background-color: #fffff6;
  margin-left: 200px;
  margin-top: 50px;
`;

export default StyledCardListDiv;
