import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import NotificationContainer from "./components/notification/NotificationContainer/NotificationContainer";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <App />
    <NotificationContainer />
  </Provider>,
);

reportWebVitals();
