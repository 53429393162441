import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useQuery = (params?: undefined) => {
  const { search } = useLocation();

  return useMemo(
    () =>
      new URLSearchParams(
        `${new URLSearchParams(params)}&${new URLSearchParams(search)}`,
      ),
    [search],
  );
};

export default useQuery;
