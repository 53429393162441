import React, { ChangeEvent, FC, useEffect } from "react";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BannerCard from "../../components/bannerCard/BannerCard";
import StyledCardListDiv from "../../styles/BannerList";
import BasicPagination from "../../components/pagination/Pagination";
import useQuery from "../../hooks/useQuery";
import {
  getBannerList,
  getSortedBannerList,
} from "../../redux/banners/actions";
import {
  selectBannerList,
  selectTotalCount,
} from "../../redux/banners/selectors";
import { store } from "../../redux/store";
import { IBanner } from "../../redux/banners/types";
import Loader from "../../components/loader/Loader";

const BannerList: FC = (): React.ReactElement => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const totalPages = useSelector(selectTotalCount);
  const bannerListData = useSelector(selectBannerList);

  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const params = Object.fromEntries(query);

  useEffect(() => {
    dispatch(getBannerList(1));
  }, [dispatch]);

  const handleChangePage = (event: number) => {
    dispatch(getBannerList(event));
    navigate({
      pathname: `${location.pathname}?`,
      search: createSearchParams({
        ...params,
        page: event.toString(),
      }).toString(),
    });
  };

  const handleSelectChange = (value: ChangeEvent<HTMLSelectElement>) => {
    if (value.target.value === "old") {
      dispatch(
        getSortedBannerList({
          pageNumber: Number(params.page ?? 1),
          sortType: "asc",
        }),
      );
    } else {
      dispatch(
        getSortedBannerList({
          pageNumber: Number(params.page ?? 1),
          sortType: "desc",
        }),
      );
    }
  };

  return (
    <div>
      {!bannerListData.length ? (
        <Loader />
      ) : (
        <>
          <select
            onChange={handleSelectChange}
            style={{
              marginLeft: "200px",
              marginTop: "20px",
              width: "250px",
              height: "40px",
              fontSize: "18px",
              border: "1px solid white",
            }}
          >
            <option value="new">Show newer banners first</option>
            <option value="old">Show older banners first</option>
          </select>
          <StyledCardListDiv currentPage={params.page}>
            {bannerListData.map((banner: IBanner) => (
              <BannerCard
                id={banner.id}
                title={banner.title}
                createdDate={banner.created_at}
                imageUrl={banner.image_url}
                status={banner.is_active}
                key={banner.id}
                publisherEmail={banner.publisher.email}
                contentImageUrl={banner.banners_content?.image_url}
                description={banner.banners_content.content}
                subtitle={banner.banners_content.title}
                pageNumber={params.page ?? "1"}
              />
            ))}
          </StyledCardListDiv>
          {bannerListData.length && (
            <BasicPagination onChange={handleChangePage} count={totalPages} />
          )}
        </>
      )}
    </div>
  );
};

export default BannerList;
