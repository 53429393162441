import styled, { keyframes } from "styled-components";

const showFlashMessage = keyframes`
  0% {
    transform: translateY(-500px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const StyledFlashMessageDiv = styled.div`
  position: fixed;
  top: 80px;
  right: -150px;
  transform: translate(-50%, -50%);
  display: inline-block;
  background-color: #ffffff;
  border-radius: 15px;
  z-index: 2;
`;

const StyledFlashMessageContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid black;
  animation: ${showFlashMessage} 0.2s ease-in-out;
`;

const StyledFlashMessageBodyDiv = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledFlashMessageIcon = styled.div`
  height: 44px;
  width: 44px;
  margin-top: 24px;
`;

const StyledFlashMessage = styled.h3`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #888d92;
`;

const StyledFlashMessageTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #020303;
`;

const StyledFlashMessageCloseButton = styled.button`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #020303;
  background-color: white;
  border: none;
  cursor: pointer;

  & svg path {
    fill: #b5b8bb;
  }
  &:hover svg path {
    fill: #888d92;
  }
`;

export {
  StyledFlashMessageDiv,
  StyledFlashMessageContentDiv,
  StyledFlashMessageBodyDiv,
  StyledFlashMessageIcon,
  StyledFlashMessage,
  StyledFlashMessageTitle,
  StyledFlashMessageCloseButton,
};
