import React, { FC, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import {
  StyledModalWarningHeader,
  StyledModalWarningButtonContainer,
  StyledModalWarningCancelButton,
} from "../../styles/ModalWarning";
import {
  selectBannerHistory,
  selectLogsTotalCountOfPages,
} from "../../redux/banners/selectors";
import convertDateToCorrectTimeFormat from "../../utils/date/convertDateToCorrentTimeFormat";
import convertDateToCorrectFormat from "../../utils/date/convertDateToCorrectFormat";
import {
  StyledModalChangeLogCard,
  StyledModalChangeLogContent,
  StyledModalChangeLogText,
} from "../../styles/ModalChangeLog";
import BasicPagination from "../pagination/Pagination";
import { getBannerHistory } from "../../redux/banners/actions";
import { store } from "../../redux/store";
import useQuery from "../../hooks/useQuery";
import {
  StyledModalEditDiv,
  StyledModalEditDivContainer,
} from "../../styles/ModalEditBanner";
import { ILog } from "../../redux/banners/types";

interface IModalChangeLog {
  onCancel: () => void;
  id: number;
}

const ModalChangeLog: FC<IModalChangeLog> = ({
  onCancel,
  id,
}): React.ReactElement => {
  const logs = useSelector(selectBannerHistory);
  const dispatch = useDispatch<typeof store.dispatch>();
  const totalPages = useSelector(selectLogsTotalCountOfPages);

  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const params = Object.fromEntries(query);

  const logsModalRef = useRef<any>(null);

  const handleChangePage = (event: number) => {
    dispatch(getBannerHistory({ id, pageNumber: event }));
    navigate({
      pathname: `${location.pathname}?`,
      search: createSearchParams({
        ...params,
        page: event.toString(),
      }).toString(),
    });
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        logsModalRef.current &&
        !logsModalRef.current.contains(event.target)
      ) {
        onCancel();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <StyledModalEditDiv>
      <StyledModalEditDivContainer ref={logsModalRef}>
        <StyledModalWarningHeader>
          <p>Change Log</p>
        </StyledModalWarningHeader>
        <StyledModalChangeLogContent>
          {logs?.map((el: ILog) => (
            <StyledModalChangeLogCard key={el.id}>
              <StyledModalChangeLogText>
                {`${el.event} at ${convertDateToCorrectTimeFormat(
                  el.updated_at,
                )} on the
            ${convertDateToCorrectFormat(el.updated_at)}`}
              </StyledModalChangeLogText>
            </StyledModalChangeLogCard>
          ))}
        </StyledModalChangeLogContent>
        {totalPages > 1 && (
          <BasicPagination onChange={handleChangePage} count={totalPages} />
        )}
        <StyledModalWarningButtonContainer>
          <StyledModalWarningCancelButton variant="outlined" onClick={onCancel}>
            Cancel
          </StyledModalWarningCancelButton>
        </StyledModalWarningButtonContainer>
      </StyledModalEditDivContainer>
    </StyledModalEditDiv>
  );
};

export default ModalChangeLog;
