import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  StyledCardBottom,
  StyledCardDiv,
  StyledCardStatus,
  StyledCardText,
  StyledCardTitle,
  StyledCardImage,
} from "../../styles/BannerCard";
import Dropdown from "./dropdown/Dropdown";
import { getBannerDetails } from "../../redux/banners/actions";
import { store } from "../../redux/store";
import convertDateToCorrectFormat from "../../utils/date/convertDateToCorrectFormat";

interface IBannerCardProps {
  id: number;
  title: string;
  createdDate: string;
  imageUrl: string;
  status: number;
  subtitle: string;
  contentImageUrl: string;
  description: string;
  publisherEmail: string;
  pageNumber: string;
}

const BannerCard: FC<IBannerCardProps> = ({
  id,
  title,
  createdDate,
  publisherEmail,
  imageUrl,
  status,
  subtitle,
  contentImageUrl,
  description,
  pageNumber,
}): React.ReactElement => {
  const dispatch = useDispatch<typeof store.dispatch>();

  const handleLinkClick = () => {
    dispatch(getBannerDetails(id));
  };

  return (
    <StyledCardDiv>
      <Link
        to={`/banner/${id}/details`}
        state={{ prev: "/" }}
        onClick={handleLinkClick}
      >
        <StyledCardImage src={imageUrl} alt="Banner" />
      </Link>
      <Dropdown
        id={id}
        bannerContentImageUrl={contentImageUrl}
        description={description}
        imageUrl={imageUrl}
        isActive={status}
        subtitle={subtitle}
        title={title}
        pageNumber={pageNumber}
      />
      <StyledCardTitle>{title}</StyledCardTitle>
      <StyledCardText>{publisherEmail}</StyledCardText>
      <StyledCardBottom>
        <StyledCardText>
          {convertDateToCorrectFormat(createdDate)}
        </StyledCardText>
        <StyledCardStatus status={status}>
          {status === 1 ? "activated" : "deactivated"}
        </StyledCardStatus>
      </StyledCardBottom>
    </StyledCardDiv>
  );
};

export default BannerCard;
