import styled from "styled-components";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    width: 300,
  },
  "& label.Mui-focused": {
    color: "#9339F2",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#9339F2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E6E6E6",
    },
    "&:hover fieldset": {
      borderColor: "#9339F2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#9339F2",
      borderWidth: "2px",
    },
  },
});

export default StyledTextField;
