import { IRootState } from "../store";
import { IBanner, IBannerDetails, ILog } from "./types";

export const selectBannerList = (state: IRootState): Array<IBanner> =>
  state.banners.data;

export const selectBannerHistory = (state: IRootState): Array<ILog> =>
  state.banners.logs;

export const selectTotalCount = (state: IRootState): number =>
  state.banners.last_page;

export const selectLogsTotalCountOfPages = (state: IRootState): number =>
  state.banners.logs_total_pages;

export const selectBannerDetails = (state: IRootState): IBannerDetails =>
  state.banners.details;
