import styled, { keyframes } from "styled-components";

const showBD = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledFlashMessageDiv = styled.div`
  animation: ${showBD} 0.4s ease-in-out;
`;

export default StyledFlashMessageDiv;
