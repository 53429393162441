import React, { FC } from "react";
import { StyledModalEditDiv } from "../../styles/ModalEditBanner";
import { StyledModalCropContainer } from "../../styles/CropImageModal";
import ImageCropper from "../Cropper/Cropper";

interface ICropImageModal {
  linkToImage: string;
  onConfirm: (image: string) => void;
  onCancel: () => void;
}

const CropContentBannerImageModal: FC<ICropImageModal> = ({
  linkToImage,
  onConfirm,
  onCancel,
}) => {
  return (
    <StyledModalEditDiv>
      <StyledModalCropContainer>
        <ImageCropper
          aspect={4 / 3}
          image={linkToImage}
          onCancel={onCancel}
          onConfirm={(image: string) => {
            onConfirm(image);
          }}
        />
      </StyledModalCropContainer>
    </StyledModalEditDiv>
  );
};

export default CropContentBannerImageModal;
