import styled, { keyframes } from "styled-components";
import Button from "@mui/material/Button";

export const showBD = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const showModal = keyframes`
  0% {
    transform: translateY(-500px);
}
  100% {
    transform: translateY(0px);
`;

const StyledModalWarningDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  animation-name: ${showBD};
  animation-duration: 0.3s;
`;

const StyledModalWarningDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 440px;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0 5px 15px rgba(5, 31, 43, 0.17);
  border-radius: 8px;
  animation-name: ${showModal};
  animation-duration: 0.3s;
`;

const StyledModalWarningHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roobert PRO", sans-serif;
  font-size: 20px;
`;

const StyledModalWarningConfirmButton = styled(Button)`
  && {
    border-radius: 8px;
    background-color: #d00a0a;
    width: 150px;

    &:hover {
      background-color: #bd0a0a;
    }
  }
`;

const StyledModalConfirmButton = styled(Button)`
  && {
    border-radius: 8px;
    background-color: #9339f2;
    width: 150px;

    &:hover {
      background-color: #531c8d;
    }
  }
`;

const StyledModalWarningCancelButton = styled(Button)`
  && {
    border-radius: 8px;
    border: 1px solid #9339f2;
    color: #9339f2;
    width: 150px;

    &:hover {
      background-color: #9339f2;
      border: 1px solid #9339f2;
      color: white;
    }
  }
`;

const StyledModalWarningButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;

export {
  StyledModalWarningDivContainer,
  StyledModalWarningHeader,
  StyledModalWarningButtonContainer,
  StyledModalWarningConfirmButton,
  StyledModalWarningCancelButton,
  StyledModalWarningDiv,
  StyledModalConfirmButton,
};
