import React, { FC } from "react";
import {
  StyledModalWarningDivContainer,
  StyledModalWarningHeader,
  StyledModalWarningButtonContainer,
  StyledModalWarningConfirmButton,
  StyledModalWarningCancelButton,
  StyledModalWarningDiv,
} from "../../styles/ModalWarning";

interface IModalWarningProps {
  onConfirm: () => void;
  onCancel: () => void;
}

const ModalWarningDelete: FC<IModalWarningProps> = ({
  onConfirm,
  onCancel,
}): React.ReactElement => {
  return (
    <StyledModalWarningDiv>
      <StyledModalWarningDivContainer>
        <StyledModalWarningHeader>
          <p>Are you sure you want to delete this banner?</p>
        </StyledModalWarningHeader>
        <StyledModalWarningButtonContainer>
          <StyledModalWarningConfirmButton
            variant="contained"
            onClick={onConfirm}
          >
            Yes, delete this banner
          </StyledModalWarningConfirmButton>
          <StyledModalWarningCancelButton variant="outlined" onClick={onCancel}>
            No
          </StyledModalWarningCancelButton>
        </StyledModalWarningButtonContainer>
      </StyledModalWarningDivContainer>
    </StyledModalWarningDiv>
  );
};

export default ModalWarningDelete;
