import { ReactComponent as HistoryDefaultIcon } from "../../assets/icons/sidebar/history/history_default.svg";
import { ReactComponent as HistorySelectedIcon } from "../../assets/icons/sidebar/history/history_selected.svg";
import { ReactComponent as BannerListDefaultIcon } from "../../assets/icons/sidebar/bannerList/banner-list_default.svg";
import { ReactComponent as BannerListSelectedIcon } from "../../assets/icons/sidebar/bannerList/banner-list_selected.svg";
import { ReactComponent as CreateDefaultIcon } from "../../assets/icons/sidebar/createBanner/create-banner_default.svg";
import { ReactComponent as CreateSelectedIcon } from "../../assets/icons/sidebar/createBanner/create-banner_selected.svg";
import { IOption } from "./types";

const SIDEBAR_OPTIONS: Array<IOption> = [
  {
    id: "banner-list",
    redirectRoute: "/",
    activeOnPaths: ["/"],
    icon: {
      iconDefault: HistoryDefaultIcon,
      iconSelected: HistorySelectedIcon,
    },
    optionText: "Banner list",
  },
  {
    id: "banners-history",
    redirectRoute: "/history",
    activeOnPaths: ["/history"],
    icon: {
      iconDefault: BannerListDefaultIcon,
      iconSelected: BannerListSelectedIcon,
    },
    optionText: "History",
  },
  {
    id: "create-banner",
    redirectRoute: "/banner/create",
    activeOnPaths: ["/banner/create"],
    icon: {
      iconDefault: CreateDefaultIcon,
      iconSelected: CreateSelectedIcon,
    },
    optionText: "Create banner",
  },
];

export default SIDEBAR_OPTIONS;
