import { FC, ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBannerHistory } from "../../redux/banners/selectors";
import {
  StyledHistoryCard,
  StyledHistoryContent,
  StyledHistoryLink,
  StyledHistoryText,
} from "../../styles/HistoryPageContent";
import convertDateToCorrectFormat from "../../utils/date/convertDateToCorrectFormat";
import { getBannerDetails } from "../../redux/banners/actions";
import { store } from "../../redux/store";
import convertDateToCorrectTimeFormat from "../../utils/date/convertDateToCorrentTimeFormat";
import { ILog } from "../../redux/banners/types";

const HistoryPageContent: FC = (): ReactElement => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const logs = useSelector(selectBannerHistory);

  const handleLinkClick = (id: number) => {
    dispatch(getBannerDetails(id));
  };

  return (
    <StyledHistoryContent>
      {logs?.map((el: ILog) => (
        <StyledHistoryCard key={el.id}>
          <StyledHistoryLink
            to={`/banner/${el.id}/details`}
            state={{ prev: "/history" }}
            onClick={() => handleLinkClick(Number(el.banner_id))}
          >
            Banner
          </StyledHistoryLink>
          <StyledHistoryText>
            {`was ${el.event} at ${convertDateToCorrectTimeFormat(
              el.updated_at,
            )} on the
            ${convertDateToCorrectFormat(el.updated_at)}`}
          </StyledHistoryText>
        </StyledHistoryCard>
      ))}
    </StyledHistoryContent>
  );
};

export default HistoryPageContent;
