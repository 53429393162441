import React, { FC } from "react";
import {
  StyledModalWarningDivContainer,
  StyledModalWarningHeader,
  StyledModalWarningButtonContainer,
  StyledModalWarningCancelButton,
  StyledModalWarningDiv,
  StyledModalConfirmButton,
} from "../../styles/ModalWarning";

interface IModalWarningProps {
  onConfirm: () => void;
  onCancel: () => void;
  status: string;
}

const ModalWarningBannerStatus: FC<IModalWarningProps> = ({
  onConfirm,
  onCancel,
  status,
}): React.ReactElement => {
  return (
    <StyledModalWarningDiv>
      <StyledModalWarningDivContainer>
        <StyledModalWarningHeader>
          <p>Are you sure you want to {status} this banner?</p>
        </StyledModalWarningHeader>
        <StyledModalWarningButtonContainer>
          <StyledModalConfirmButton variant="contained" onClick={onConfirm}>
            Yes, {status} this banner
          </StyledModalConfirmButton>
          <StyledModalWarningCancelButton variant="outlined" onClick={onCancel}>
            No
          </StyledModalWarningCancelButton>
        </StyledModalWarningButtonContainer>
      </StyledModalWarningDivContainer>
    </StyledModalWarningDiv>
  );
};

export default ModalWarningBannerStatus;
