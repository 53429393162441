import styled from "styled-components";

const StyledModalChangeLogContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModalChangeLogCard = styled.div`
  width: 400px;
  padding: 20px;
  display: flex;
  margin-top: 5px;
  flex-direction: row;
  background-color: #f3f3f3;
  border-radius: 15px;
  font-family: "Roobert PRO", sans-serif;
`;

const StyledModalChangeLogText = styled.span`
  margin-left: 5px;
  font-family: "Roobert PRO", sans-serif;
`;

export {
  StyledModalChangeLogContent,
  StyledModalChangeLogCard,
  StyledModalChangeLogText,
};
