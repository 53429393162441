import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  StyledBannerDetailsDiv,
  StyledMainInfoDiv,
  StyledToolbarButton,
  StyledToolbarDiv,
} from "../../styles/BannerDetails";
import BannerContentPreview from "../../components/bannerPreview/bannerContentPreview/BannerContentPreview";
import BannerImagePreview from "../../components/bannerPreview/bannerImagePreview/BannerImagePreview";
import ModalWarningDelete from "../../components/modalWarningDelete/ModalWarningDelete";
import ModalWarningBannerStatus from "../../components/modalWarningBannerStatus/ModalWarningBannerStatus";
import ModalEditBanner from "../../components/modalEditBanner/ModalEditBanner";
import { selectBannerDetails } from "../../redux/banners/selectors";
import convertDateToCorrectFormat from "../../utils/date/convertDateToCorrectFormat";
import { StyledCardText, StyledCardTitle } from "../../styles/BannerCard";
import Loader from "../../components/loader/Loader";
import {
  changeBannerStatus,
  deleteBanner,
  getBannerDetails,
  getBannerHistory,
  getBannerList,
} from "../../redux/banners/actions";
import { store } from "../../redux/store";
import ModalChangeLog from "../../components/modalChageLog/modalChangeLog";

const BannerDetails: FC = (): React.ReactElement => {
  const navigate = useNavigate();
  const [modalWarningDelete, setModalWarningDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalChangeLog, setModalChangeLog] = useState(false);
  const [modalWarningBannerStatus, setModalWarningBannerStatus] =
    useState(false);

  const dispatch = useDispatch<typeof store.dispatch>();

  const details = useSelector(selectBannerDetails);

  const handleEditBanner = () => {
    setModalEdit(true);
  };

  const handleChangeStatus = () => {
    setModalWarningBannerStatus(true);
  };

  const openChangeLog = () => {
    dispatch(getBannerHistory({ id: details?.id, pageNumber: 1 }));
    setModalChangeLog(true);
  };

  const handleDeleteBanner = () => {
    setModalWarningDelete(true);
  };

  return (
    <StyledBannerDetailsDiv>
      {!details.id ? (
        <Loader />
      ) : (
        <>
          <StyledToolbarDiv>
            <StyledToolbarButton onClick={handleEditBanner}>
              Edit
            </StyledToolbarButton>
            <StyledToolbarButton onClick={handleChangeStatus}>
              {!details?.is_active ? "Activate" : "Deactivate"}
            </StyledToolbarButton>
            <StyledToolbarButton onClick={openChangeLog}>
              View change log
            </StyledToolbarButton>
            <StyledToolbarButton onClick={handleDeleteBanner}>
              Delete
            </StyledToolbarButton>
          </StyledToolbarDiv>
          <div>
            <StyledMainInfoDiv>
              <StyledCardTitle>{details?.title}</StyledCardTitle>
              <StyledCardText>{details?.publisher.email}</StyledCardText>
              <StyledCardText>
                {convertDateToCorrectFormat(details?.created_at)}
              </StyledCardText>
            </StyledMainInfoDiv>
            <BannerImagePreview imageUrl={details?.image_url} />
          </div>
          <BannerContentPreview
            imageUrl={details?.banners_content.image_url}
            subtitle={details?.banners_content.title}
            description={details?.banners_content.content}
          />
          {modalWarningDelete && (
            <ModalWarningDelete
              onCancel={() => {
                setModalWarningDelete(false);
              }}
              onConfirm={() => {
                setModalWarningDelete(false);
                dispatch(
                  deleteBanner({
                    id: details?.id,
                    action: getBannerList(1),
                    navigate,
                  }),
                );
              }}
            />
          )}
          {modalChangeLog && (
            <ModalChangeLog
              onCancel={() => {
                setModalChangeLog(false);
              }}
              id={details?.id}
            />
          )}
          {modalWarningBannerStatus && (
            <ModalWarningBannerStatus
              onCancel={() => {
                setModalWarningBannerStatus(false);
              }}
              onConfirm={() => {
                setModalWarningBannerStatus(false);
                dispatch(
                  changeBannerStatus({
                    id: details?.id,
                    action: getBannerDetails(details?.id),
                  }),
                );
              }}
              status={!details?.is_active ? "activate" : "deactivate"}
            />
          )}
          {modalEdit && (
            <ModalEditBanner
              bannerInfoProps={{
                id: details?.id,
                linkToBannerImage: details?.image_url,
                title: details?.title,
                linkToContentPageBannerImage:
                  details?.banners_content.image_url,
                subtitle: details?.banners_content.title,
                description: details?.banners_content.content,
                isActive: details?.is_active,
              }}
              onCancel={() => {
                setModalEdit(false);
              }}
              onConfirm={() => {
                setModalEdit(false);
                navigate("/");
              }}
              pageNumber="1"
            />
          )}
        </>
      )}
    </StyledBannerDetailsDiv>
  );
};

export default BannerDetails;
