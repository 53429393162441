async function convertImageUrlToFile(url: string) {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = {
    type: "image/jpeg",
  };
  const file = new File([data], "banner.jpeg", metadata);
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = "";
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

export default convertImageUrlToFile;
