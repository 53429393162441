import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthRespData, IAuthState, ICreds } from "./types";
import { VANIPAK_TOKEN, VENIPAK_AUTH_STATE } from "../../constants/auth";
import { login } from "./actions";

const defaultState: IAuthState = {
  user: {
    id: 0,
    email: "",
    roleId: 0,
  },
  accessToken: "",
  isAuthed: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: defaultState,
  extraReducers: (builder) => {
    builder.addCase(
      login.fulfilled,
      (
        state: IAuthState,
        action: PayloadAction<
          IAuthRespData,
          string,
          { arg: ICreds; requestId: string; requestStatus: "fulfilled" }
        >,
      ) => {
        state.user.id = action.payload.user.id;
        state.user.email = action.payload.user.email;
        state.user.roleId = action.payload.user.role_id;
        state.accessToken = action.payload.access_token;
        state.isAuthed = true;
        localStorage.setItem(VENIPAK_AUTH_STATE, JSON.stringify(state));
        localStorage.setItem(
          VANIPAK_TOKEN,
          `Bearer ${action.payload.access_token}`,
        );
      },
    );
  },
  reducers: {
    logout() {
      localStorage.removeItem(VENIPAK_AUTH_STATE);
      localStorage.removeItem(VANIPAK_TOKEN);
      return defaultState;
    },
  },
});

export default authSlice;
