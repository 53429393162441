import React, { FC, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
  StyledModalWarningDivContainer,
  StyledModalWarningHeader,
  StyledModalWarningButtonContainer,
  StyledModalWarningConfirmButton,
  StyledModalWarningCancelButton,
  StyledModalWarningDiv,
} from "../../styles/ModalWarning";

interface IModalChangeOrder {
  onConfirm: (orderNumber?: string) => void;
  onCancel: () => void;
}

const ModalChangeOrder: FC<IModalChangeOrder> = ({
  onConfirm,
  onCancel,
}): React.ReactElement => {
  const [orderNumber, setOrderNumber] = useState("");

  const handleChangeOrder = (event: SelectChangeEvent) => {
    setOrderNumber(event.target.value as string);
  };

  return (
    <StyledModalWarningDiv>
      <StyledModalWarningDivContainer>
        <StyledModalWarningHeader>
          <p>Choose a number in order:</p>
        </StyledModalWarningHeader>
        <Select value={orderNumber} onChange={handleChangeOrder}>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
        </Select>
        <StyledModalWarningButtonContainer>
          <StyledModalWarningConfirmButton
            variant="contained"
            onClick={() => onConfirm(orderNumber)}
          >
            Save
          </StyledModalWarningConfirmButton>
          <StyledModalWarningCancelButton variant="outlined" onClick={onCancel}>
            Cancel
          </StyledModalWarningCancelButton>
        </StyledModalWarningButtonContainer>
      </StyledModalWarningDivContainer>
    </StyledModalWarningDiv>
  );
};

export default ModalChangeOrder;
