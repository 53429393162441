import styled from "styled-components";

const StyledLoaderDiv = styled.div`
  position: absolute;
  top: 50%;
  right: 45%;
  transform: translate(0, -50%);
`;

export default StyledLoaderDiv;
