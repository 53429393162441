import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledSidebarNav = styled.nav`
  flex-shrink: 0;
  transition: width 0.3s;
  display: flex;
`;

const StyledSidebarLink = styled(Link)`
  margin-bottom: 50px;
`;

const StyledSidebarDiv = styled.div`
  width: 130px;
  position: fixed;
  left: 0;
  z-index: 2;
  height: 100vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-right: 1px solid #e3e4e5;
  > * {
    &:last-child {
      margin-top: auto;
      margin-bottom: 60px;
    }
  }
`;

export { StyledSidebarNav, StyledSidebarDiv, StyledSidebarLink };
