import { createSlice } from "@reduxjs/toolkit";
import { IBannersState } from "./types";
import {
  getBannerDetails,
  getBannerHistory,
  getBannerList,
  getBannersChangeLog,
  getSortedBannerList,
} from "./actions";

const defaultState: IBannersState = {
  data: [],
  logs: [],
  logs_total_pages: 0,
  last_page: 0,
  details: {
    id: 0,
    image_url: "",
    title: "",
    content: "",
    is_active: false,
    created_at: "",
    banners_content: {
      id: 0,
      image_url: "",
      title: "",
      content: "",
    },
    publisher: {
      email: "",
    },
  },
};

const bannersSlice = createSlice({
  name: "banners",
  initialState: defaultState,
  extraReducers: (builder) => {
    builder.addCase(getBannerList.fulfilled, (state, action) => {
      state.data = action.payload.banners.data;
      state.last_page = action.payload.banners.last_page;
    });
    builder.addCase(getBannerDetails.fulfilled, (state, action) => {
      state.details = action.payload.banner;
    });
    builder.addCase(getSortedBannerList.fulfilled, (state, action) => {
      state.data = action.payload.banners.data;
      state.last_page = action.payload.banners.last_page;
    });
    builder.addCase(getBannersChangeLog.fulfilled, (state, action) => {
      state.logs = action.payload.logs.data;
      state.logs_total_pages = action.payload.logs.last_page;
    });
    builder.addCase(getBannerHistory.fulfilled, (state, action) => {
      state.logs = action.payload.logs.data;
      state.logs_total_pages = action.payload.logs.last_page;
    });
  },
  reducers: {},
});

export default bannersSlice;
