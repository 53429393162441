import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "./redux/auth";
import { logoutRequest } from "./redux/auth/actions";
import { store } from "./redux/store";
import AppRoutes from "./routes/AppRoutes";

const App = () => {
  const dispatch = useDispatch<typeof store.dispatch>();

  window.addEventListener("on", (ev) => {
    ev.preventDefault();
    dispatch(logoutRequest());
    dispatch(logout());
  });

  const router = createBrowserRouter(AppRoutes);

  return <RouterProvider router={router} />;
};

export default App;
