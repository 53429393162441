import React from "react";
import { Navigate } from "react-router-dom";
import { VANIPAK_TOKEN } from "../constants/auth";

interface IProps {
  children: React.ReactElement;
}

const AuthHoc: React.FC<IProps> = ({ children }): React.ReactElement => {
  if (!localStorage.getItem(VANIPAK_TOKEN)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthHoc;
