import { useLocation } from "react-router-dom";
import { IOption } from "../types";

const useIsSelectedFromPathname = (): ((option: IOption) => boolean) => {
  const location = useLocation();

  return (option: IOption): boolean => {
    if (option.id === "banner-list") {
      return location.pathname === option.activeOnPaths[0];
    }
    if (option.activeOnPaths.length) {
      for (let i = 0; i < option.activeOnPaths.length; i++) {
        if (location.pathname.includes(option.activeOnPaths[i])) {
          return true;
        }
      }
    }
    return false;
  };
};

export default useIsSelectedFromPathname;
