import Cropper, { Area } from "react-easy-crop";
import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import StyledCropper from "../../styles/Cropper";
import {
  StyledCancelButton,
  StyledModalBannerButtonContainer,
  StyledSaveBannerButton,
} from "../../styles/CreateBannerPage";
import { store } from "../../redux/store";
import { setNotification } from "../../redux/notification";
import { Status } from "../../redux/notification/types";

interface IImageCropper {
  image: string;
  aspect: number;
  onCancel: () => void;
  onConfirm: (croppedImage: string) => void;
}

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  }).catch((error) => {
    throw error;
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getCroppedImg(
  imageSrc: string,
  pixelCrop: { x: number; y: number; width: number; height: number },
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return canvas.toDataURL("image/jpeg");
}

const ImageCropper: FC<IImageCropper> = ({
  image,
  aspect,
  onCancel,
  onConfirm,
}) => {
  const dispatch = useDispatch<typeof store.dispatch>();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<null | {
    x: number;
    y: number;
    width: number;
    height: number;
  }>(null);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedPixels: Area) => {
      setCroppedAreaPixels(croppedPixels);
    },
    [],
  );

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels as {
          x: number;
          y: number;
          width: number;
          height: number;
        },
        rotation,
      );
      onConfirm(croppedImage as string);
    } catch (error) {
      onCancel();
      dispatch(
        setNotification({
          title: "Image saving error",
          message: `The image from this website can't be used due to Cross Origin Request Policy. Please pick another one`,
          status: Status.ERROR,
        }),
      );
    }
  };

  const handleClickConfirm = () => {
    showCroppedImage();
  };

  return (
    <StyledCropper>
      <Cropper
        style={{ containerStyle: { height: "80%" } }}
        image={image}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={aspect}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
      <StyledModalBannerButtonContainer>
        <StyledSaveBannerButton
          variant="contained"
          onClick={handleClickConfirm}
        >
          Add image
        </StyledSaveBannerButton>
        <StyledCancelButton variant="outlined" onClick={onCancel}>
          Cancel
        </StyledCancelButton>
      </StyledModalBannerButtonContainer>
    </StyledCropper>
  );
};

export default ImageCropper;
