import styled from "styled-components";
import Button from "@mui/material/Button";
import { showModal } from "./ModalWarning";

const StyledCropButton = styled(Button)`
  && {
    border-radius: 8px;
    background-color: #9339f2;
    width: 40px;
    margin-top: 10px;
    margin-left: 15px;

    &:hover {
      background-color: green;
    }
  }
`;

const StyledModalCropContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 60%;
  max-height: 90vh;
  padding: 27px;
  background: #f6f6f6;
  box-shadow: 0 5px 15px rgba(5, 31, 43, 0.17);
  border-radius: 8px;
  animation-name: ${showModal};
  animation-duration: 0.3s;
`;

export { StyledCropButton, StyledModalCropContainer };
