import CircularProgress from "@mui/material/CircularProgress";
import StyledLoaderDiv from "../../styles/Loader";

const Loader = () => {
  return (
    <StyledLoaderDiv>
      <CircularProgress disableShrink />
    </StyledLoaderDiv>
  );
};

export default Loader;
