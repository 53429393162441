import React, { FC } from "react";
import bannerContainer from "../../../assets/images/banner-border-preview.jpg";
import {
  StyledImageContainer,
  StyledImage,
} from "../../../styles/BannerPreview";

const BannerImagePreview: FC<{ imageUrl: string | undefined }> = ({
  imageUrl,
}): React.ReactElement => {
  return (
    <div style={{ position: "relative" }}>
      <StyledImageContainer src={bannerContainer} alt="Banner" />
      {imageUrl && <StyledImage src={imageUrl} alt="Banner" />}
    </div>
  );
};

export default BannerImagePreview;
