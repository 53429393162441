// Function for asynchronous requests, which calls the logout when the token is expired

import { Dispatch } from "@reduxjs/toolkit";
import { VANIPAK_TOKEN } from "../constants/auth";
import { logout } from "../redux/auth";
import { logoutRequest } from "../redux/auth/actions";

interface IMakeRequest {
  (
    dispatch: Dispatch,
    url: RequestInfo,
    init?: RequestInit | undefined,
  ): Promise<Response>;
}

const makeRequest: IMakeRequest = async (
  dispatch: Dispatch,
  url: RequestInfo,
  init = undefined,
) => {
  const token = localStorage.getItem(VANIPAK_TOKEN) || "invalid_token";

  let initWithToken;

  if (!init) {
    initWithToken = {
      headers: {
        Accept: "application/json",
        Authorization: `${token}`,
      },
    };
  } else if (!init.headers) {
    initWithToken = {
      ...init,
      headers: {
        Accept: "application/json",
        Authorization: `${token}`,
      },
    };
  } else {
    initWithToken = init;
    initWithToken.headers = Object.defineProperty(
      initWithToken.headers,
      "Authorization",
      {
        value: `${token}`,
        writable: false,
        enumerable: true,
        configurable: false,
      },
    );
  }

  const response: Response = await fetch(url, initWithToken);
  if (response.status === 401) {
    dispatch(logoutRequest() as any);
    dispatch(logout());
  }
  return response;
};

export default makeRequest;
