import styled from "styled-components";
import { NavLink } from "react-router-dom";

const StyledSidebarOptionDiv = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #888d92;
  text-decoration: none;
  font-family: "Roobert PRO", sans-serif;
  font-size: 14px;
  flex-shrink: 0;
`;

const StyledSidebarOptionNavLink = styled(NavLink)`
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #888d92;
  text-decoration: none;
`;

export { StyledSidebarOptionDiv, StyledSidebarOptionNavLink };
