import Login from "../pages/login/Login";
import BannerList from "../pages/bannerList/BannerList";
import CreateBanner from "../pages/createBanner/CreateBanner";
import Sidebar from "../components/sidebar/Sidebar";
import BannerDetails from "../pages/bannerDetails/BannerDetails";
import AuthHoc from "../hoc/AuthHoc";
import History from "../pages/history/History";

const AppRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/banner/create",
    element: (
      <>
        <Sidebar />
        <AuthHoc>
          <CreateBanner />
        </AuthHoc>
      </>
    ),
  },
  {
    path: "/*",
    element: (
      <>
        <Sidebar />
        <AuthHoc>
          <BannerList />
        </AuthHoc>
      </>
    ),
  },
  {
    path: "/banner/:id/details",
    element: (
      <>
        <Sidebar />
        <AuthHoc>
          <BannerDetails />
        </AuthHoc>
      </>
    ),
  },
  {
    path: "/history*",
    element: (
      <>
        <Sidebar />
        <AuthHoc>
          <History />
        </AuthHoc>
      </>
    ),
  },
];

export default AppRoutes;
