import { createSlice } from "@reduxjs/toolkit";
import { INotificationsState } from "./types";

const initialState: INotificationsState = {
  notices: [],
};

const NotificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotification(state, action) {
      return {
        ...state,
        notices: [...state.notices, { ...action.payload, id: Date.now() }],
      };
    },
    removeNotification(state, action) {
      return {
        ...state,
        notices: state.notices.filter(
          (notice: { id: number }) => notice.id !== action.payload,
        ),
      };
    },
  },
});

export default NotificationsSlice;
