import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAuthRespData, IAuthRespError, ICreds } from "./types";
import BASE_URL from "../../constants/API";
import { setNotification } from "../notification";
import { Status } from "../notification/types";
import { VANIPAK_TOKEN } from "../../constants/auth";

const login = createAsyncThunk(
  "auth/login",
  async (payload: ICreds, thunkApi) => {
    const response = await fetch(`${BASE_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      const errorData: IAuthRespError = await response.json();

      if (response.status === 401) {
        thunkApi.dispatch(
          setNotification({
            title: "Unauthorized",
            message: `${
              errorData.message ??
              errorData.messages.email ??
              errorData.messages.password
            }`,
            status: Status.ERROR,
          }),
        );
      }

      if (response.status === 422) {
        thunkApi.dispatch(
          setNotification({
            title: "Validation error",
            message: `${
              errorData.message ??
              errorData.messages.email ??
              errorData.messages.password
            }`,
            status: Status.ERROR,
          }),
        );
      }
      return thunkApi.rejectWithValue(errorData);
    }
    const authData: IAuthRespData = await response.json();
    return authData;
  },
);

const logoutRequest = createAsyncThunk("auth/logout", async () => {
  const token = localStorage.getItem(VANIPAK_TOKEN) || "invalid_token";
  await fetch(`${BASE_URL}/logout`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({}),
  });
});

export { login, logoutRequest };
