import styled from "styled-components";
import { showBD, showModal } from "./ModalWarning";

const StyledModalEditDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  animation-name: ${showBD};
  animation-duration: 0.3s;
`;

const StyledModalEditDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: fit-content;
  max-height: 90vh;
  overflow: scroll;
  padding: 27px;
  background: #f6f6f6;
  box-shadow: 0 5px 15px rgba(5, 31, 43, 0.17);
  border-radius: 8px;
  animation-name: ${showModal};
  animation-duration: 0.3s;
`;

const StyledTitleEditModalContainer = styled.div`
  display: flex;
  margin: 0;
  border-bottom: 2px solid white;
  font-size: 24px;
  > * {
    &:last-child {
      margin-left: 320px;
    }
  }
`;

export {
  StyledModalEditDiv,
  StyledModalEditDivContainer,
  StyledTitleEditModalContainer,
};
