import { ChangeEvent } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StyledPaginationDiv from "../../styles/Pagination";
import useQuery from "../../hooks/useQuery";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9339f2",
    },
  },
});

interface IProps {
  onChange: (page: number) => void;
  count: number;
}

const BasicPagination = ({ count, onChange }: IProps) => {
  const query = useQuery();

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    onChange(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledPaginationDiv>
        <Stack spacing={2}>
          <Pagination
            count={count}
            color="primary"
            onChange={handleChange}
            defaultPage={Number(query.get("page")) + 1}
          />
        </Stack>
      </StyledPaginationDiv>
    </ThemeProvider>
  );
};

export default BasicPagination;
