import { useDispatch, useSelector } from "react-redux";
import { FC, useCallback } from "react";
import getNotifications from "../../../redux/notification/selectors";
import { removeNotification } from "../../../redux/notification";
import NotificationItem from "../NotificationItem/NotificationItem";
import StyledFlashMessageDiv from "../../../styles/NotificationContainer";
import { Notification } from "../../../redux/notification/types";

const NotificationContainer: FC = () => {
  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();

  const closeNotification = useCallback(
    (id: number | string) => () => {
      dispatch(removeNotification(id));
    },
    [],
  );

  return (
    <StyledFlashMessageDiv>
      {notifications.map((notification: Notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          closeNotification={closeNotification(notification.id)}
        />
      ))}
    </StyledFlashMessageDiv>
  );
};

export default NotificationContainer;
