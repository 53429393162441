import convertDataURLtoFile from "./convertDataUrlToFile";
import convertImageUrlToFile from "./convertImageUrlToBase64";

const getFile = async (link: string, img?: string) => {
  if (img?.length) {
    return convertDataURLtoFile(img as string, "banner.jpeg");
  }
  const file = await convertImageUrlToFile(link);
  return convertDataURLtoFile(file as string, "banner.jpeg");
};

export default getFile;
