import React, { FC, useState } from "react";
import Menu from "@mui/material/Menu";
import { useDispatch } from "react-redux";
import { StyledCardDropdownIcon } from "../../../styles/BannerCard";
import { StyledMenuIconButton, StyledMenuItem } from "../../../styles/Dropdown";
import ModalWarningDelete from "../../modalWarningDelete/ModalWarningDelete";
import ModalWarningBannerStatus from "../../modalWarningBannerStatus/ModalWarningBannerStatus";
import ModalEditBanner from "../../modalEditBanner/ModalEditBanner";
import ModalChangeOrder from "../../modalChangeOrder/ModalChangeOrder";
import {
  changeBannerStatus,
  changePriorityOfBanner,
  deleteBanner,
  getBannerHistory,
  getBannerList,
} from "../../../redux/banners/actions";
import { store } from "../../../redux/store";
import ModalChangeLog from "../../modalChageLog/modalChangeLog";

enum MenuItems {
  EDIT = "Edit",
  VIEW_CHANGE_LOG = "View change log",
  ACTIVATE = "Activate",
  DEACTIVATE = "Deactivate",
  CHANGE_ORDER = "Change order",
  DELETE = "Delete",
}

interface IDropDown {
  id: number;
  isActive: number;
  imageUrl: string;
  title: string;
  bannerContentImageUrl: string;
  subtitle: string;
  description: string;
  pageNumber: string;
}

const Dropdown: FC<IDropDown> = ({
  id,
  isActive,
  imageUrl,
  title,
  bannerContentImageUrl,
  subtitle,
  description,
  pageNumber,
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalWarningDelete, setModalWarningDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalChangeOrder, setModalChangeOrder] = useState(false);
  const [modalChangeLog, setModalChangeLog] = useState(false);
  const [modalWarningBannerStatus, setModalWarningBannerStatus] =
    useState(false);

  const dispatch = useDispatch<typeof store.dispatch>();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleViewChangeLog = () => {
    setAnchorEl(null);
    dispatch(getBannerHistory({ id, pageNumber: 1 }));
    setModalChangeLog(true);
  };

  const handleClickChangeOrder = () => {
    setAnchorEl(null);
    setModalChangeOrder(true);
  };

  const handleClickChangeBannerStatus = () => {
    setAnchorEl(null);
    setModalWarningBannerStatus(true);
  };

  const handleClickDeleteBanner = () => {
    setAnchorEl(null);
    setModalWarningDelete(true);
  };

  const handleClickEditBanner = () => {
    setAnchorEl(null);
    setModalEdit(true);
  };

  const handleConfirm = async () => {
    setModalEdit(false);
  };

  return (
    <>
      <StyledMenuIconButton
        aria-controls="simple-menu"
        disableRipple
        onClick={handleClick}
      >
        <StyledCardDropdownIcon />
      </StyledMenuIconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          disablePadding: true,
        }}
        keepMounted
      >
        <StyledMenuItem onClick={handleClickEditBanner}>
          {MenuItems.EDIT}
        </StyledMenuItem>
        <StyledMenuItem onClick={handleViewChangeLog}>
          {MenuItems.VIEW_CHANGE_LOG}
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClickChangeBannerStatus}>
          {isActive === 1 ? MenuItems.DEACTIVATE : MenuItems.ACTIVATE}
        </StyledMenuItem>
        {isActive === 1 && (
          <StyledMenuItem onClick={handleClickChangeOrder}>
            {MenuItems.CHANGE_ORDER}
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={handleClickDeleteBanner}>
          {MenuItems.DELETE}
        </StyledMenuItem>
      </Menu>
      {modalWarningDelete && (
        <ModalWarningDelete
          onCancel={() => setModalWarningDelete(false)}
          onConfirm={() => {
            setModalWarningDelete(false);
            dispatch(
              deleteBanner({ id, action: getBannerList(Number(pageNumber)) }),
            );
          }}
        />
      )}
      {modalWarningBannerStatus && (
        <ModalWarningBannerStatus
          onCancel={() => setModalWarningBannerStatus(false)}
          onConfirm={() => {
            setModalWarningBannerStatus(false);
            dispatch(
              changeBannerStatus({
                id,
                action: getBannerList(Number(pageNumber)),
              }),
            );
          }}
          status={
            isActive !== 1
              ? MenuItems.ACTIVATE.toLowerCase()
              : MenuItems.DEACTIVATE.toLowerCase()
          }
        />
      )}
      {modalChangeOrder && (
        <ModalChangeOrder
          onCancel={() => setModalChangeOrder(false)}
          onConfirm={(orderNumber) => {
            setModalChangeOrder(false);
            if (orderNumber) {
              dispatch(
                changePriorityOfBanner({
                  id,
                  orderNumber,
                  action: getBannerList(Number(pageNumber)),
                }),
              );
            }
          }}
        />
      )}
      {modalChangeLog && (
        <ModalChangeLog onCancel={() => setModalChangeLog(false)} id={id} />
      )}
      {modalEdit && (
        <ModalEditBanner
          bannerInfoProps={{
            id,
            linkToBannerImage: imageUrl,
            title,
            linkToContentPageBannerImage: bannerContentImageUrl,
            subtitle,
            description,
            isActive: Boolean(isActive),
          }}
          onCancel={() => setModalEdit(false)}
          onConfirm={handleConfirm}
          pageNumber={pageNumber}
        />
      )}
    </>
  );
};

export default Dropdown;
