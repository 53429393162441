import React, { useMemo } from "react";
import { IOption, ISidebarOptionProps } from "../types";
import useIsSelectedFromPathname from "./useIsSelectedFromPathname";
import {
  StyledSidebarOptionDiv,
  StyledSidebarOptionNavLink,
} from "../../../styles/SidebarOption";

const SidebarOption = ({ option }: ISidebarOptionProps): React.ReactElement => {
  const {
    icon: { iconDefault: IconDefault, iconSelected: IconSelected },
    optionText,
  } = option;
  const isSelectedFromPath: (option: IOption) => boolean =
    useIsSelectedFromPathname();
  const selectedFromPath: boolean = useMemo(
    () => isSelectedFromPath(option),
    [option, isSelectedFromPath],
  );

  return (
    <StyledSidebarOptionNavLink to={option.redirectRoute}>
      <StyledSidebarOptionDiv>
        {selectedFromPath ? <IconSelected /> : <IconDefault />}
        <h4>{optionText}</h4>
      </StyledSidebarOptionDiv>
    </StyledSidebarOptionNavLink>
  );
};

export default SidebarOption;
