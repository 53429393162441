import styled from "styled-components";
import Button from "@mui/material/Button";

const StyledCreateBannerPageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 20px 50px 200px;
  background-color: #f3f3f3;
  border-radius: 15px;
  font-family: "Roobert PRO", sans-serif;
  position: relative;
`;

const StyledCreateBannerContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px 0;
  margin-bottom: 50px;
`;

const StyledTitleContainer = styled.h1`
  display: flex;
  margin: 0;
  border-bottom: 2px solid white;
  & span {
    font-size: 1.17em;
    margin: 37px 0 37px 0;
  }
  > * {
    &:last-child {
      margin-left: 235px;
    }
  }
`;

const StyledMainBannerDiv = styled.div`
  display: flex;
  gap: 40px 10px;
  padding-bottom: 30px;
  border-bottom: 2px solid white;
`;

const StyledAdditionalPageBannerDiv = styled.div`
  display: flex;
  gap: 40px 10px;
`;

const StyledBannerButtonContainer = styled.div`
  display: flex;
  gap: 30px;
  padding-top: 40px;
  align-self: end;
  border-top: 2px solid white;
`;

const StyledModalBannerButtonContainer = styled.div`
  position: absolute;
  display: flex;
  gap: 30px;
  padding-top: 40px;
  align-self: end;
  border-top: 2px solid white;
  bottom: 0;
  right: 0;
`;

const StyledSaveBannerButton = styled(Button)`
  && {
    border-radius: 8px;
    background-color: #9339f2;
    width: 200px;

    &:hover {
      background-color: green;
    }
  }
`;

const StyledCancelButton = styled(Button)`
  && {
    border-radius: 8px;
    border: 2px solid red;
    color: red;
    width: 200px;

    &:hover {
      background-color: red;
      border: 2px solid red;
      color: white;
    }
  }
`;

const StyledDeleteImageButton = styled.button`
  cursor: pointer;
  background-color: red;
  border: 1px solid white;
  color: white;
  border-radius: 10px;
  padding: 5px;
  width: 100px;
`;

const StyledTextFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-right: 100px;
`;

const StyledErrorSpan = styled.span`
  color: red;
  margin-top: -20px;
`;

export {
  StyledCreateBannerPageDiv,
  StyledMainBannerDiv,
  StyledAdditionalPageBannerDiv,
  StyledSaveBannerButton,
  StyledCancelButton,
  StyledBannerButtonContainer,
  StyledCreateBannerContainerDiv,
  StyledTitleContainer,
  StyledTextFields,
  StyledModalBannerButtonContainer,
  StyledErrorSpan,
  StyledDeleteImageButton,
};
