export enum Status {
  WARNING = "warning",
  ERROR = "error",
  DONE = "done",
}

export interface Notification {
  id: number;
  message: string;
  title: string;
  status: Status;
}

export interface INotificationsState {
  notices: Array<Notification>;
}
