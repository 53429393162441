import { IconButton, MenuItem as MuiMenuItem, styled } from "@mui/material";

const StyledMenuItem = styled(MuiMenuItem)({
  "&.MuiMenuItem-root": {
    padding: "8px",
    fontSize: "16px",
    "&:last-child": {
      color: "red",
    },
    "&:hover": {
      backgroundColor: "#9339f2",
      color: "white",
    },
  },
});

const StyledMenuIconButton = styled(IconButton)({
  width: "30px",
  height: "45px",
  alignSelf: "end",
  backgroundColor: "transparent",
});

export { StyledMenuIconButton, StyledMenuItem };
