import React, { FC, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ReactComponent as Eye } from "../../assets/icons/login/eye.svg";
import { ReactComponent as EyeSlashed } from "../../assets/icons/login/eye_slashed.svg";
import StyledTextField from "../../styles/TextField";
import StyledForm from "../../styles/Form";
import StyledButton from "../../styles/Button";
import { login } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import { selectIsAuthed } from "../../redux/auth/selectors";

const Login: FC = (): React.ReactElement => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch<typeof store.dispatch>();
  const isAuthed = useSelector(selectIsAuthed);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleCheckEmail = () => {
    /.+@.+\.[A-Za-z]+$/.test(email)
      ? setEmailError(false)
      : setEmailError(true);
  };

  const handleCheckPassword = () => {
    password.length ? setPasswordError(false) : setPasswordError(true);
  };

  const handleSubmitLogin = () => {
    handleCheckEmail();
    handleCheckPassword();

    if (email.length && password.length) {
      dispatch(
        login({
          email,
          password,
        }),
      );
    }
  };

  return isAuthed ? (
    <Navigate to="/" />
  ) : (
    <StyledForm noValidate data-testid="login">
      <h1>Login</h1>
      <StyledTextField
        label="Email address"
        type="email"
        value={email}
        error={emailError}
        onChange={handleChangeEmail}
      />
      <StyledTextField
        label="Password"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handleChangePassword}
        error={passwordError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <EyeSlashed /> : <Eye />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <StyledButton type="button" onClick={handleSubmitLogin}>
        Login
      </StyledButton>
    </StyledForm>
  );
};

export default Login;
