import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/index";
import NotificationsReducer from "./notification/index";
import BannersReducer from "./banners/index";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    notifications: NotificationsReducer,
    banners: BannersReducer,
  },
});

export type IRootState = ReturnType<typeof store.getState>;
export type IDispatch = ReturnType<typeof store.dispatch>;
