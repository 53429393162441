import { FC, ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getBannersChangeLog } from "../../redux/banners/actions";
import { store } from "../../redux/store";
import HistoryPageContent from "./HistoryPageContent";
import useQuery from "../../hooks/useQuery";
import { selectLogsTotalCountOfPages } from "../../redux/banners/selectors";
import BasicPagination from "../../components/pagination/Pagination";

const History: FC = (): ReactElement => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const totalPages = useSelector(selectLogsTotalCountOfPages);

  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const params = Object.fromEntries(query);

  useEffect(() => {
    dispatch(getBannersChangeLog(1));
  }, [dispatch]);

  const handleChangePage = (event: number) => {
    dispatch(getBannersChangeLog(event));
    navigate({
      pathname: `${location.pathname}?`,
      search: createSearchParams({
        ...params,
        page: event.toString(),
      }).toString(),
    });
  };

  return (
    <div>
      <HistoryPageContent />
      {totalPages > 1 && (
        <BasicPagination onChange={handleChangePage} count={totalPages} />
      )}
    </div>
  );
};

export default History;
