import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledHistoryContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  margin-top: 50px;
`;

const StyledHistoryCard = styled.div`
  width: 600px;
  padding: 20px;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  background-color: #f3f3f3;
  border-radius: 15px;
  font-family: "Roobert PRO", sans-serif;
`;

const StyledHistoryText = styled.span`
  margin-left: 5px;
  font-family: "Roobert PRO", sans-serif;
`;

const StyledHistoryLink = styled(Link)`
  text-decoration: none;
  color: #9339f2;
  font-weight: bold;
`;

export {
  StyledHistoryContent,
  StyledHistoryCard,
  StyledHistoryText,
  StyledHistoryLink,
};
