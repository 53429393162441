import styled from "styled-components";

const StyledButton = styled.button`
  height: 48px;
  width: 300px;
  background-color: #9339f2;
  color: white;
  padding: 10px 16px;
  border-radius: 12px;
  border: 1px solid #9339f2;
  font-size: 14px;
  cursor: pointer;
`;

export default StyledButton;
