import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  StyledModalEditDiv,
  StyledModalEditDivContainer,
  StyledTitleEditModalContainer,
} from "../../styles/ModalEditBanner";
import {
  StyledAdditionalPageBannerDiv,
  StyledBannerButtonContainer,
  StyledCancelButton,
  StyledDeleteImageButton,
  StyledMainBannerDiv,
  StyledSaveBannerButton,
  StyledTextFields,
} from "../../styles/CreateBannerPage";
import {
  IBannerInfo,
  IBannerInfoError,
  IBannerProps,
  ITextFieldInfo,
} from "../../pages/createBanner/types";
import {
  handleCheckDescriptionField,
  handleCheckLinkToContentPageField,
  handleCheckLinkToFileField,
  handleCheckSubtitleField,
  handleCheckTitleField,
} from "../../constants/banner";
import StyledTextField from "../../styles/TextField";
import { StyledCropButton } from "../../styles/CropImageModal";
import BannerImagePreview from "../bannerPreview/bannerImagePreview/BannerImagePreview";
import BannerContentPreview from "../bannerPreview/bannerContentPreview/BannerContentPreview";
import CropBannerImageModal from "../cropImageModal/CropBannerImageModal";
import CropContentBannerImageModal from "../cropImageModal/CropContentBannerImageModal";
import {
  changeBannerStatus,
  editBanner,
  getBannerList,
} from "../../redux/banners/actions";
import { store } from "../../redux/store";
import ModalWarningDeleteCroppedImage from "../modalDeleteCroppedImage/ModalWarningDelete";
import getFile from "../../utils/banner/getFile";
import ModalWarningEditBanner from "../modalWarningEditBanner/ModalWarningEditBanner";
import ModalSaveOptions from "../modalSaveOptions/ModalSaveOptions";

interface IModalEditBanner {
  bannerInfoProps: IBannerProps;
  onConfirm: () => void;
  onCancel: () => void;
  pageNumber: string;
}

const ModalEditBanner: FC<IModalEditBanner> = ({
  bannerInfoProps,
  onCancel,
  onConfirm,
  pageNumber = "1",
}): React.ReactElement => {
  const [showModalWarningCancelEdit, setShowModalWarningCancelEdit] =
    useState(false);
  const [showModalSaveOptions, setShowModalSaveOptions] = useState(false);
  const [
    showModalWarningBannerImageDelete,
    setShowModalWarningBannerImageDelete,
  ] = useState(false);
  const [
    showModalWarningContentBannerImageDelete,
    setShowModalWarningContentBannerImageDelete,
  ] = useState(false);
  const dispatch = useDispatch<typeof store.dispatch>();
  const [showCropMainImageModal, setShowCropMainImageModal] = useState(false);
  const [showCropContainerImageModal, setShowCropContainerImageModal] =
    useState(false);
  const [bannerInfo, setBannerInfo] = useState<IBannerInfo>({
    linkToBannerImage: "",
    title: "",
    linkToContentPageBannerImage: "",
    subtitle: "",
    description: "",
  });

  const [bannerInfoError, setBannerInfoError] = useState<IBannerInfoError>({
    linkToBannerImageError: false,
    titleError: false,
    linkToContentPageBannerImageError: false,
    subtitleError: false,
    descriptionError: false,
    croppedBannerImage: false,
    croppedContentBannerImage: false,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    setBannerInfo(bannerInfoProps);
  }, [bannerInfoProps]);

  const onChangeBannerInfo = (info: ITextFieldInfo[] | ITextFieldInfo) => {
    const newData = Array.isArray(info)
      ? info.reduce(
          (acc: Record<string, string | undefined>, item: ITextFieldInfo) => {
            acc[item.key] = item.image || item.text;
            return acc;
          },
          {},
        )
      : { [info.key]: info.text || info.image };

    setBannerInfo((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const deleteCroppedImage = () => {
    onChangeBannerInfo([
      {
        key: "croppedBannerImage",
        text: "",
        image: "",
      },
      { key: "linkToBannerImage", text: "" },
    ]);
    setShowModalWarningBannerImageDelete(false);
  };

  const deleteCroppedContentImage = () => {
    onChangeBannerInfo([
      {
        key: "croppedContentBannerImage",
        text: "",
        image: "",
      },
      { key: "linkToContentPageBannerImage", text: "" },
    ]);
    setShowModalWarningContentBannerImageDelete(false);
  };

  const submitBanner = async () => {
    setIsFirstLoad(false);
    const croppedBannerImageFile = await getFile(
      bannerInfo.linkToBannerImage,
      bannerInfo.croppedBannerImage,
    );

    const croppedContentBannerImageFile = await getFile(
      bannerInfo.linkToContentPageBannerImage,
      bannerInfo.croppedContentBannerImage,
    );

    if (
      !bannerInfoError.linkToBannerImageError &&
      !bannerInfoError.titleError &&
      !bannerInfoError.linkToContentPageBannerImageError &&
      !bannerInfoError.subtitleError &&
      !bannerInfoError.descriptionError
    ) {
      dispatch(
        editBanner({
          id: bannerInfoProps.id,
          banner_image: croppedBannerImageFile,
          banner_title: bannerInfo.title,
          content_image: croppedContentBannerImageFile,
          content_title: bannerInfo.subtitle,
          content_text: bannerInfo.description,
          action: getBannerList(Number(pageNumber)),
          successCallback: onConfirm,
        }),
      );
    }
  };

  const handleUpdate = () => {
    if (bannerInfoProps.isActive) {
      setShowModalSaveOptions(true);
    } else {
      submitBanner();
    }
  };

  const saveAndDeactivate = () => {
    setShowModalSaveOptions(false);
    dispatch(
      changeBannerStatus({
        id: bannerInfoProps.id as number,
      }),
    );
    submitBanner();
  };

  const saveAndPublish = () => {
    setShowModalSaveOptions(false);
    submitBanner();
  };

  const handleCroppedBannerImageDelete = () => {
    setShowModalWarningBannerImageDelete(true);
  };

  const handleCroppedContentBannerImageDelete = () => {
    setShowModalWarningContentBannerImageDelete(true);
  };

  useEffect(() => {
    if (!isFirstLoad) {
      handleCheckLinkToFileField(bannerInfo, setBannerInfoError);
      handleCheckTitleField(bannerInfo, setBannerInfoError);
      handleCheckLinkToContentPageField(bannerInfo, setBannerInfoError);
      handleCheckSubtitleField(bannerInfo, setBannerInfoError);
      handleCheckDescriptionField(bannerInfo, setBannerInfoError);
    }
  }, [isFirstLoad, submitBanner]);

  const handleCancelChanges = () => {
    if (JSON.stringify(bannerInfo) !== JSON.stringify(bannerInfoProps)) {
      setShowModalWarningCancelEdit(true);
    } else {
      onCancel();
    }
  };

  const keepEditing = () => {
    setShowModalWarningCancelEdit(false);
  };

  const discardChanges = () => {
    setShowModalWarningCancelEdit(false);
    onCancel();
  };

  return (
    <StyledModalEditDiv>
      <StyledModalEditDivContainer>
        <StyledTitleEditModalContainer>
          <h3>Edit banner</h3>
          <h3>Preview</h3>
        </StyledTitleEditModalContainer>
        <StyledMainBannerDiv>
          <StyledTextFields>
            {bannerInfo.croppedBannerImage?.length ? (
              <StyledDeleteImageButton
                type="button"
                onClick={handleCroppedBannerImageDelete}
              >
                Delete image
              </StyledDeleteImageButton>
            ) : (
              ""
            )}
            <div>
              <StyledTextField
                required
                label="Link to file"
                value={bannerInfo.linkToBannerImage}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChangeBannerInfo({
                    key: "linkToBannerImage",
                    text: e.target.value,
                  });
                }}
              />
              <StyledCropButton
                onClick={() => setShowCropMainImageModal(true)}
                variant="contained"
                color="primary"
              >
                Add
              </StyledCropButton>
            </div>
            <StyledTextField
              required
              label="Title"
              error={bannerInfoError.titleError}
              helperText={bannerInfoError.titleError && "Please enter title"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeBannerInfo({
                  key: "title",
                  text: e.target.value,
                });
              }}
              value={bannerInfo.title}
              inputProps={{ maxLength: 256, minLength: 1 }}
            />
          </StyledTextFields>
          <BannerImagePreview
            imageUrl={
              bannerInfo.croppedBannerImage ?? bannerInfo.linkToBannerImage
            }
          />
        </StyledMainBannerDiv>
        <StyledAdditionalPageBannerDiv>
          <StyledTextFields>
            {bannerInfo.croppedContentBannerImage?.length ? (
              <StyledDeleteImageButton
                type="button"
                onClick={handleCroppedContentBannerImageDelete}
              >
                Delete image
              </StyledDeleteImageButton>
            ) : (
              ""
            )}
            <div>
              <StyledTextField
                required
                label="Link to file"
                inputProps={{ minLength: 1 }}
                value={bannerInfo.linkToContentPageBannerImage}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChangeBannerInfo({
                    key: "linkToContentPageBannerImage",
                    text: e.target.value,
                  });
                }}
              />
              <StyledCropButton
                onClick={() => setShowCropContainerImageModal(true)}
                variant="contained"
                color="primary"
              >
                Add
              </StyledCropButton>
            </div>
            <StyledTextField
              required
              label="Subtitle"
              value={bannerInfo.subtitle}
              error={bannerInfoError.subtitleError}
              helperText={
                bannerInfoError.subtitleError && "Please enter subtitle"
              }
              inputProps={{ maxLength: 256, minLength: 1 }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeBannerInfo({
                  key: "subtitle",
                  text: e.target.value,
                });
              }}
            />
            <StyledTextField
              required
              label="Description"
              value={bannerInfo.description}
              multiline
              rows={8}
              error={bannerInfoError.descriptionError}
              helperText={
                bannerInfoError.descriptionError && "Please enter description"
              }
              inputProps={{ maxLength: 2048, minLength: 1 }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeBannerInfo({
                  key: "description",
                  text: e.target.value,
                });
              }}
            />
          </StyledTextFields>
          <BannerContentPreview
            imageUrl={
              bannerInfo.croppedContentBannerImage ??
              bannerInfo.linkToContentPageBannerImage
            }
            subtitle={bannerInfo.subtitle}
            description={bannerInfo.description}
          />
        </StyledAdditionalPageBannerDiv>
        {showCropMainImageModal && (
          <CropBannerImageModal
            linkToImage={bannerInfo.linkToBannerImage}
            onCancel={() => setShowCropMainImageModal(false)}
            onConfirm={(croppedImage: string) => {
              setShowCropMainImageModal(false);
              onChangeBannerInfo({
                key: "croppedBannerImage",
                image: croppedImage,
              });
            }}
          />
        )}
        {showCropContainerImageModal && (
          <CropContentBannerImageModal
            linkToImage={bannerInfo.linkToContentPageBannerImage}
            onCancel={() => setShowCropContainerImageModal(false)}
            onConfirm={(croppedImage: string) => {
              setShowCropContainerImageModal(false);
              onChangeBannerInfo({
                key: "croppedContentBannerImage",
                image: croppedImage,
              });
            }}
          />
        )}
        {showModalWarningBannerImageDelete && (
          <ModalWarningDeleteCroppedImage
            onCancel={() => setShowModalWarningBannerImageDelete(false)}
            onConfirm={deleteCroppedImage}
          />
        )}
        {showModalWarningContentBannerImageDelete && (
          <ModalWarningDeleteCroppedImage
            onCancel={() => setShowModalWarningContentBannerImageDelete(false)}
            onConfirm={deleteCroppedContentImage}
          />
        )}
        {showModalWarningCancelEdit && (
          <ModalWarningEditBanner
            onCancel={keepEditing}
            onConfirm={discardChanges}
          />
        )}
        {showModalSaveOptions && (
          <ModalSaveOptions
            onSave={saveAndDeactivate}
            onSaveAndPublish={saveAndPublish}
          />
        )}
        <StyledBannerButtonContainer>
          <StyledSaveBannerButton variant="contained" onClick={handleUpdate}>
            Edit banner
          </StyledSaveBannerButton>
          <StyledCancelButton variant="outlined" onClick={handleCancelChanges}>
            Cancel
          </StyledCancelButton>
        </StyledBannerButtonContainer>
      </StyledModalEditDivContainer>
    </StyledModalEditDiv>
  );
};

export default ModalEditBanner;
