import Button from "@mui/material/Button";
import styled from "styled-components";

export const StyledBannerDetailsDiv = styled.div`
  max-width: 90%;
  display: flex;
  gap: 35px 100px;
  flex-wrap: wrap;
  padding: 40px;
  border-radius: 15px;
  justify-content: center;
  margin: 20px 20px 50px 200px;
  background-color: #f3f3f3;
  font-family: "Roobert PRO", sans-serif;
  position: relative;
`;

export const StyledToolbarDiv = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  justify-content: center;
  width: 100%;
`;

export const StyledToolbarButton = styled(Button)`
  && {
    border-radius: 8px;
    border: 2px solid #9339f2;
    color: #9339f2;
    width: 180px;
    height: 40px;
    font-weight: 600;

    &:hover {
      background-color: #9339f2;
      border: 1px solid #9339f2;
      color: white;
    }
  }
`;

export const StyledMainInfoDiv = styled.div`
  padding: 10px;
  margin: 10px;
  width: fit-content;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
