import styled from "styled-components";
import { ReactComponent as DropdownIcon } from "../assets/icons/bannerCard/dropdown.svg";

const StyledCardDiv = styled.div`
  max-width: 300px;
  height: 260px;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  border-radius: 15px;
  font-family: "Roobert PRO", sans-serif;
  overflow: hidden;
`;

const StyledCardImage = styled.img`
  width: 300px;
  height: 155px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  cursor: pointer;
`;

const StyledCardBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-bottom: 8px;
`;

const StyledCardDropdownIcon = styled(DropdownIcon)`
  width: 20px;
  height: 20px;
  align-self: end;
  fill: #220e24;
  margin: 10px 5px;
  position: relative;
  cursor: pointer;
`;

const StyledCardTitle = styled.h1`
  padding: 0 10px;
  font-size: 19px;
  max-width: 250px;
  margin: -35px 0 0;
  color: #220e24;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledCardStatus = styled.span<{ status: number }>`
  margin-right: 10px;
  color: white;
  padding: 3px 8px;
  font-size: 15px;
  background-color: ${(props: { status: number }) =>
    props.status === 1 ? "#9339f2" : "#8b76a5"};
  border-radius: 10px;
`;

const StyledCardText = styled.p`
  margin: 0;
  padding: 0 10px;
  color: #9493a1;
  font-size: 15px;
`;

export {
  StyledCardDropdownIcon,
  StyledCardDiv,
  StyledCardTitle,
  StyledCardText,
  StyledCardStatus,
  StyledCardBottom,
  StyledCardImage,
};
