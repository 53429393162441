import React, { FC } from "react";
import statusBar from "../../../assets/images/status-bar-preview.png";
import {
  StyledBannerContentPreviewDiv,
  StyledBorderImage,
  StyledSubtitle,
  StyledDescription,
  StyledContentImage,
} from "../../../styles/BannerPreview";

interface IBannerContentPreview {
  imageUrl: string | undefined;
  subtitle: string;
  description: string;
}

const BannerContentPreview: FC<IBannerContentPreview> = ({
  imageUrl,
  subtitle,
  description,
}): React.ReactElement => {
  return (
    <StyledBannerContentPreviewDiv>
      <StyledBorderImage src={statusBar} alt="Status bar" />
      {imageUrl ? <StyledContentImage src={imageUrl} alt="Banner" /> : ""}
      <StyledSubtitle>{subtitle}</StyledSubtitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledBannerContentPreviewDiv>
  );
};

export default BannerContentPreview;
